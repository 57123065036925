<template>
  <div>
    <v-row class="d-none d-md-flex">
      <v-col xs="12" md="11" class="">
        <v-simple-table dense>
          <thead></thead>

          <tbody>
            <tr>
              <td style="width: 250px">City</td>
              <td>
                {{ deal_summary_data.city }},
                {{ deal_summary_data.province }}
              </td>
            </tr>

            <tr>
              <td style="width: 250px">Location Notes</td>
              <td>{{ deal_summary_data.location_notes }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Property Type</td>
              <td>{{ deal_summary_data.property_type }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Property Value</td>
              <td>
                {{ deal_summary_data.property_value | formatPrice }}
              </td>
            </tr>

            <tr>
              <td style="width: 250px">Property Value Notes</td>
              <td>{{ deal_summary_data.property_value_notes }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <br />

        <v-simple-table dense>
          <tbody>
            <tr>
              <td style="width: 250px">Owing Against Property</td>
              <td>
                {{ deal_summary_data.owing_against | formatPrice }}
              </td>
            </tr>

            <tr>
              <td style="width: 250px">Owing Against Property Notes</td>
              <td>{{ deal_summary_data.owing_against_notes }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <br />

        <v-simple-table dense>
          <tbody>
            <tr>
              <td style="width: 250px">Amount Requested</td>
              <td>
                {{ deal_summary_data.amount_requested | formatPrice }}
              </td>
            </tr>

            <tr>
              <td style="width: 250px">Amount Requested Notes</td>
              <td>
                {{ deal_summary_data.amount_requested_notes }}
              </td>
            </tr>

            <tr>
              <td style="width: 250px">Loan To Value</td>
              <td>{{ deal_summary_data.loan_to_value }}%</td>
            </tr>

            <tr>
              <td style="width: 250px">Mortgage Position</td>
              <td>
                {{ deal_summary_data.mortgage_position | mortgagePosition }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <br />

        <v-simple-table dense>
          <tbody>
            <tr>
              <td style="width: 250px">Income Notes</td>
              <td>{{ deal_summary_data.income_notes }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Liability Notes</td>
              <td>{{ deal_summary_data.liability_notes }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Credit Notes</td>
              <td>{{ deal_summary_data.credit_notes }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Exit Strategy</td>
              <td>{{ deal_summary_data.exit_strategy }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Summary Notes</td>
              <td>{{ deal_summary_data.summary_notes }}</td>
            </tr>

            <tr>
              <td style="width: 250px">GDS</td>
              <td>{{ deal_summary_data.GDS }}%</td>
            </tr>

            <tr>
              <td style="width: 250px">TDS</td>
              <td>{{ deal_summary_data.TDS }}%</td>
            </tr>
          </tbody>
        </v-simple-table>

        <br />

        <v-simple-table dense>
          <tbody>
            <tr>
              <td style="width: 250px">Suggested Mortgage Position</td>
              <td>{{ deal_summary_data.mortgage_position }}</td>
            </tr>

            <tr>
              <td style="width: 250px">Suggested Term</td>
              <td>{{ deal_summary_data.proposed_term }} Months</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row justify="center" class="d-md-none">
      <v-col xs="12" md="11" class="">
        <h4>City</h4>
        <p>
          {{ deal_summary_data.city }},
          {{ deal_summary_data.province }}
        </p>

        <h4>Location Notes</h4>
        <p>{{ deal_summary_data.location_notes }}</p>

        <h4>Property Type</h4>
        <p>{{ deal_summary_data.property_type }}</p>

        <hr />

        <br />

        <h4>Property Value</h4>
        <p>
          {{ deal_summary_data.property_value | formatPrice }}
        </p>

        <h4>Property Value Notes</h4>
        <p>{{ deal_summary_data.property_value_notes }}</p>

        <h4>Owing Against Property</h4>
        <p>
          {{ deal_summary_data.owing_against | formatPrice }}
        </p>

        <h4>Owing Against Property Notes</h4>
        <p>{{ deal_summary_data.owing_against_notes }}</p>

        <hr />

        <br />

        <h4>Amount Requested</h4>
        <p>
          {{ deal_summary_data.amount_requested | formatPrice }}
        </p>

        <h4>Amount Requested Notes</h4>
        <p>
          {{ deal_summary_data.amount_requested_notes }}
        </p>

        <h4>Loan To Value</h4>
        <p>{{ deal_summary_data.loan_to_value }}%</p>

        <h4>Mortgage Position</h4>
        <p>{{ deal_summary_data.mortgage_position | mortgagePosition }}</p>

        <hr />

        <br />

        <h4>Income Notes</h4>
        <p>{{ deal_summary_data.income_notes }}</p>

        <h4>Liability Notes</h4>
        <p>{{ deal_summary_data.liability_notes }}</p>

        <h4>Credit Notes</h4>
        <p>{{ deal_summary_data.credit_notes }}</p>

        <h4>Exit Strategy</h4>
        <p>{{ deal_summary_data.exit_strategy }}</p>

        <h4>Summary Notes</h4>
        <p>{{ deal_summary_data.summary_notes }}</p>

        <hr />

        <br />

        <h4>GDS</h4>
        <p>{{ deal_summary_data.GDS }}%</p>

        <h4>TDS</h4>
        <p>{{ deal_summary_data.TDS }}%</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <br />

        <v-btn
          color="green darken-4"
          dark
          v-if="deal_summary_data.status == 'active'"
          @click="show_add_offer_dialog = true"
          @close="
            show_add_offer_dialog = false;
            edit_summary_offer_data = {};
            edit_summary_offer_dialog = false;
          "
        >
          Make a new offer
        </v-btn>

        <v-btn
          class="ml-4"
          color="red darken-4"
          dark
          v-if="
            deal_summary_data.status == 'active' &&
            (view_type == 'summary_link_token' || view_type == 'logged_in')
          "
          @click="show_decline_comment_dialog = true"
          @close="show_decline_comment_dialog = false"
        >
          Decline Deal
        </v-btn>

        <decline-comment
          :company="company"
          :deal_summary_data="deal_summary_data"
          :dialog="show_decline_comment_dialog"
          :summary_link_token="summary_link_token"
          :view_type="view_type"
          @close="show_decline_comment_dialog = false"
        />

        <Add_Deal_Summary_Offer
          :company="company"
          :deal_summary_data="deal_summary_data"
          :view_type="view_type"
          :summary_link_token="summary_link_token"
          :dialog="show_add_offer_dialog"
          @getDealInfo="getDealInfo"
          @close="
            show_add_offer_dialog = false;
            edit_summary_offer_data = {};
            edit_summary_offer_dialog = false;
          "
          :edit_summary_offer_dialog="edit_summary_offer_dialog"
          :edit_summary_offer_data="edit_summary_offer_data"
        />
      </v-col>
    </v-row>

    <br />

    <v-row class="d-none d-md-flex">
      <v-col
        v-if="deal_summary_data.summary_offers.length >= 1"
        xs="12"
        md="11"
        class=""
      >
        <h3>Existing Offers</h3>

        <br />

        <p>
          Below is a list of existing offers from all lenders including your
          company. The edit button is visible on offers your company has
          submitted. You are able to use the edit function on the offers until
          an offer has been selected by the client and the deal is no longer
          available.
        </p>

        <br />

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>

                <th class="text-left">Date</th>

                <th class="text-left">Amount</th>

                <th class="text-left">Position</th>

                <th class="text-left">Term</th>

                <th class="text-left">Interest Rate</th>

                <th class="text-left">Lender Fee</th>

                <th class="text-left">Yield</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in deal_summary_data.summary_offers"
                :key="item.id"
              >
                <td style="width: 30px">
                  <span
                    style="color: green; font-size: 12px; cursor: pointer"
                    v-if="item.my_offer"
                    @click="edit_summary_offer_dialog_activate(index)"
                  >
                    (edit)
                  </span>
                </td>

                <td>
                  <div style="position: relative">
                    {{ item.createdAt | dateFormat }}
                  </div>
                </td>

                <td>{{ item.mortgage_amount | formatPrice }}</td>

                <td>
                  {{ item.mortgage_position | mortgagePosition }}
                </td>

                <td>{{ item.mortgage_term }} Months</td>

                <td>{{ item.interest_rate }}%</td>

                <td>{{ item.lender_fee }}%</td>

                <td>{{ item.lender_fee + item.interest_rate }}%</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col v-else>No Existing Offers</v-col>
    </v-row>

    <v-row class="d-flex d-md-none">
      <v-col
        v-if="deal_summary_data.summary_offers.length >= 1"
        xs="12"
        md="11"
        class=""
      >
        <h3>Existing Offers</h3>

        <br />

        <p style="font-size: 12px">
          Below is a list of existing offers from all lenders including your
          company. The edit button is visible on offers your company has
          submitted. You are able to use the edit function on the offers until
          an offer has been selected by the client and the deal is no longer
          available.
        </p>

        <Mobile_Existing_Offers
          v-for="(item, index) in deal_summary_data.summary_offers"
          :offer_data="item"
          :index="index"
          :key="item.id"
          @edit_summary_offer_dialog_activate_mobile="
            edit_summary_offer_dialog_activate($event)
          "
        />
      </v-col>

      <v-col v-else>
        <h3>No Existing Offers</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from '@/plugins/API';
import filters from '@/plugins/filters';

import Add_Deal_Summary_Offer from '@/components/Add_Deal_Summary_Offer';
import Mobile_Existing_Offers from '@/components/Mobile_Existing_Offers';
import DeclineComment from './DeclineComment.vue';

export default {
  props: ['deal_summary_data', 'company', 'view_type', 'summary_link_token'],

  components: {
    Add_Deal_Summary_Offer,
    Mobile_Existing_Offers,
    DeclineComment,
  },

  data() {
    return {
      edit_summary_offer_data: {},
      edit_summary_offer_dialog: false,
      show_add_offer_dialog: false,
      show_decline_comment_dialog: false,
      error: false,
      offer_submit_error: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },

  methods: {
    edit_summary_offer_dialog_activate(index) {
      this.edit_summary_offer_data =
        this.deal_summary_data.summary_offers[index];
      this.edit_summary_offer_dialog = true;
      this.show_add_offer_dialog = true;
    },

    async getDealInfo() {
      this.$emit('getDealInfo');
    },
  },

  async mounted() {
    // await this.getDealInfo();
  },

  filters: {
    ...filters,
  },
};
</script>

<style lang="scss" scoped>
tr:hover {
  background-color: transparent !important;
}
</style>
