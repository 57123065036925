<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card v-if="this.isLoading"> loading... </v-card>
    <v-card v-if="!this.isLoading">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Decline Deal</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn dark text @click="dialog = false"> Submit </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <h2 class="my-10">
          On behalf of {{ company.name }} you choose to decline this offer.
        </h2>

        <v-textarea
          dark
          outlined
          label="Please leave a comment. (optional)"
          v-model="comments"
        />

        <v-btn
          v-show="!isSubmitted"
          color="green darken-4"
          dark
          @click="submitDecline"
        >
          Submit
        </v-btn>

        <v-btn
          v-show="!isSubmitted"
          class="ml-4"
          color="red darken-4"
          dark
          @click="$emit('close')"
        >
          Cancel
        </v-btn>

        <div class="mt-5">
          <v-alert
            dense
            type="success"
            v-show="isSubmitted"
            transition="scroll-y-transition"
          >
            Successfully submitted! Thank you for your time.
          </v-alert>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import API from '@/plugins/API';

export default {
  name: 'DeclineComment',

  props: [
    'dialog',
    'company',
    'deal_summary_data',
    'view_type',
    'summary_link_token',
  ],

  data() {
    return {
      isLoading: true,
      isMyOfferExist: false,
      isSubmitted: false,
      comments: '',
    };
  },

  methods: {
    async submitDecline() {
      if (this.view_type === 'logged_in') {
        // for user logged in

        const response = await API()
          .post(`/api/create_user_decline_summary_offer`, {
            deal_summary_id: parseInt(this.deal_summary_data.id),
            company_id: parseInt(this.company.id),
            comments: this.comments,
          })
          .catch((err) => console.log(err));

        this.comments = '';

        console.log('this is the entry data: ', response);

        this.isSubmitted = true;

        setTimeout(() => {
          this.isSubmitted = false;
          this.$emit('close');
          this.$router.push({
            path: `/dashboard/mortgagesAvailable/list`,
          });
        }, 2000);
      } else if (this.view_type === 'summary_link_token') {
        // for user has link access

        const response = await API()
          .post(
            `/api/link_create_user_decline_summary_offer?summary_link_token=${this.summary_link_token}`,
            {
              deal_summary_id: parseInt(this.deal_summary_data.id),
              company_id: parseInt(this.company.id),
              comments: this.comments,
            }
          )
          .catch((err) => console.log(err));

        this.comments = '';

        console.log('this is the entry data: ', response);

        this.isSubmitted = true;

        setTimeout(() => {
          this.isSubmitted = false;
          this.$emit('close');
          this.$router.push({
            path: `/login`,
          });
        }, 2000);
      } else {
        alert('You are not authorize to view this page.');
        window.close();
      }
    },
  },

  mounted() {
    this.isLoading = false;
  },
};
</script>
